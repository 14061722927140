<style scoped>
.menu_add {
    background: #A5ACB4 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 50px;
    text-align: center;
}

.menu_add_text {
    color: #FFFFFF;
}

.menu_main {
    background-color: #F5F7F8;
    font: normal normal 600 18px/25px Apple SD Gothic Neo;
    border: 1px solid #D3D8DD;
    border-radius: 5px;
    height: 50px;
}

.menu_main_text {
    color: #333333;
}

.menu_sub {
    height: 50px;
}

.menu_sub_text {
    color: #333333;
}

.selected_menu {
    font-weight: 600 !important;
    color: #333333 !;
    background-color: #DFE7EF;
    font: normal normal 600 18px/25px Apple SD Gothic Neo;
    border: 1px solid #B8C8D8;
    border-radius: 5px;
    height: 50px;
}

.menu_text_ml1 {
    margin-left: 0px;
}

.menu_text_ml2 {
    margin-left: 25px;
}

.menu_text_ml3 {
    margin-left: 50px;
}

.menu_text_ml4 {
    margin-left: 75px;
}

</style>
<template>
    <v-sheet>
        <v-list class="pt-0">
            <!-- <v-list-item class="mb-4 menu_add" link @click="addMenu()">
              <v-list-item-content>
                <v-list-item-title class="menu_add_text">
                  <v-icon color="#FFFFFF" size="20" class="mr-1">mdi-plus-circle</v-icon>
                  메뉴추가
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <template v-for="(treeItem, treeIdx) in menuTree">
                <v-list-item
                    class="mb-2"
                    :class="{'menu_main' : treeItem.menu_depth == 1, 'menu_sub' : treeItem.menu_depth > 1, 'selected_menu' : treeItem.menu_seqno == selectedMenuSeqno}"
                    link
                    :key="'tree_' + treeIdx"
                    @click="selectMenu(treeItem.item)"
                    v-if="treeItem.view_flag"
                >
                    <v-list-item-content>
                        <v-list-item-title
                            class="font-weight-normal menu_sub_text"
                            :class="{'menu_text_ml1': treeItem.menu_depth == 1, 'menu_text_ml2': treeItem.menu_depth == 2, 'menu_text_ml3': treeItem.menu_depth == 3, 'menu_text_ml4': treeItem.menu_depth == 4}"
                        >
                            <v-btn icon @click="toggleMenuOpen(treeItem)">
                                <v-icon v-if="treeItem.menu_type != 'FOLDER' || treeItem.menu_depth == 4" size="28">mdi-circle-medium</v-icon>
                                <v-icon v-else-if="treeItem.open_flag" size="33">mdi-menu-down</v-icon>
                                <v-icon v-else size="33">mdi-menu-right</v-icon>
                            </v-btn>
                            {{ treeItem.item.menu_name }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu offset-y v-if="treeItem.add_child_flag || treeItem.change_order_flag">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-list style="width:200px;">
                                <v-list-item link @click="addMenu(treeItem.item)" v-if="treeItem.add_child_flag">
                                    <v-list-item-title>하위 메뉴 추가</v-list-item-title>
                                </v-list-item>
                                <v-list-item link @click="openMenuOrder(treeItem.item)" v-if="treeItem.change_order_flag">
                                    <v-list-item-title>메뉴 순서 변경</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>

            </template>
            <v-list-item class="mb-4 menu_add" link @click="addMenu()">
                <v-list-item-content>
                    <v-list-item-title class="menu_add_text">
                        <v-icon color="#FFFFFF" size="20" class="mr-1">mdi-plus-circle</v-icon>
                        메뉴추가
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list>

    </v-sheet>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: 'svc_voice_scn_menus',
    components: {},
    data: () => ({}),
    created() {
    },
    computed: {
        ...mapGetters({
            menuTree: 'svcVoiceScn/getMenuTree',
            menuList: 'svcVoiceScn/getMenuList',
            selectedMenuSeqno: 'svcVoiceScn/getSelectedMenuSeqno',
            selectedMenu: 'svcVoiceScn/getSelectedMenu',
        }),
        isLoaded() {
            if (this.isLoaded) {
                return true
            }
            return false
        },
        filteredMenuList() {
            let ret = []

            if (this.menuList) {
                this.menuList.forEach(item => {
                    if (item.menu_type != 'RING_USER') {
                        ret.push(item)
                    }
                })
            }
            return ret
        }
    },
    methods: {
        reloadMenuList() {
            let va = this
            this.$store.dispatch('svcVoiceScn/getMenuList', {})
        },
        addMenu(item) {
            if (!item) {
                this.$store.commit('svcVoiceScn/selectMenu', null)
            } else {
                this.$store.commit('svcVoiceScn/selectMenu', item)
            }

            let selectedMenu = this.selectedMenu

            if (selectedMenu && selectedMenu.menu_type != 'FOLDER') {
                this.$store.dispatch('openAlert', {
                    message: '메뉴를 추가 할 수 없습니다',
                    sub_message: '[' + selectedMenu.menu_name + ']는 하위 메뉴를 추가 할 수 없는 메뉴 입니다'
                })
            } else if (selectedMenu && selectedMenu.menu_depth >= 4) {
                this.$store.dispatch('openAlert', {
                    message: '메뉴를 추가 할 수 없습니다',
                    sub_message: '메뉴는 최대 4 depth 까지 등록 할 수 있습니다.'
                })
            } else {
                this.$emit('addMenu')
            }
        },
        selectMenu(item) {
            this.$store.commit('svcVoiceScn/selectMenu', item)
            this.$emit('selectMenu')
        },
        toggleMenuOpen(treeItem) {
            this.$store.commit('svcVoiceScn/toggleMenuOpen', treeItem)
            this.$store.commit('svcVoiceScn/resetTreeFlags', treeItem)
        },
        openMenuOrder(item) {
            this.$store.commit('svcVoiceScn/selectMenu', item)
            this.$emit('openMenuOrder', item)
        }
    }
}</script>